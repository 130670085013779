/*
 * central place to import env vars and add default value to them
 */
const trueWords = [ 'yes', '1', 'ja', 'on' ];

export const BaseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost/tdbdev/';
export const LogUrl = process.env.VUE_APP_LOG_URL || 'http://localhost/csp/';
export const AdminParty = trueWords.includes(process.env.VUE_APP_ADMIN_PARTY);
export const IsLive = BaseUrl.includes('api.fibl-services.org');
export const EtrackerCode = process.env.VUE_APP_ETRACKER_CODE || null;
export const CommitSha = process.env.VUE_APP_COMMIT_SHA || 'dev';

let tmp = BaseUrl.replace(/\/$/, '');
export const DOMAIN = tmp.substr(tmp.lastIndexOf('/') + 1);
export const ADMIN = document.location.pathname.includes('/admin') || document.location.hostname.includes('admin');

export const KeycloakInfo = process.env.VUE_APP_KEYCLOAK_INFO
	? JSON.parse(process.env.VUE_APP_KEYCLOAK_INFO)
	: {
		'realm': 'zdb',
		'url': 'http://localhost/auth/',
		'ssl-required': 'none',
		'resource': 'zdb',
		'public-client': true,
		'confidential-port': 0,
		'clientId': 'zdb'
	};
