import { getAccountDetails, listAccountsForUser, unregisterAccount, updateAccount, registerWithDomainByInvite } from '@/repository/auth/account.js';
import { getEntitiesById } from '@/repository/system.js';
import { toAccountName } from '@/plugins/filters.js';
import { EtrackerCode } from '@/util/env.js';
import * as TdbRepo from '@/repository/tdb.js';
import * as LocalStorage from '@/repository/localStorage.js';

/*
 * everything for your own current user
 */

const state = {
	details: {},
	loaded: false,
	availableAccounts: []
};

const getters = {
	details: state => {
		return state.details;
	},
	availableAccounts: (state, getters, rootState, rootGetters) => {
		let isAdmin = rootGetters['country/isAdmin'];
		return state.availableAccounts.filter(a => (a.data.registrationOptionName === 'adm' ? isAdmin : !isAdmin));
	},
	accountName: (state) => {
		return toAccountName(state.details);
	},
	supData: (state) => {
		return state.details?.userData?.supData || [];
	},
	insData: (state) => {
		return state.details?.userData?.insData || [];
	},
	role: (state) => {
		return state.details?.staticData?.role || 'GUEST';
	},
	offerAutApproval: (state) => {
		return state.details?.staticData?.autApprovalForCountry || null;
	},
	roleSupportsProfile: (state) => (func) => {
		let role = state.details?.staticData?.role;
		if (func === 'logo') return ['SUP'].includes(role);
		if (func === 'email') return ['ASO', 'AUT', 'INS', 'SUP'].includes(role);
		if (func === 'animalkindsSold') return ['AGR', 'SUP'].includes(role);
		if (func === 'locationsOfSale') return ['AGR', 'SUP'].includes(role);
		if (func === 'confirmedAllLocationsOfSale') return ['AGR'].includes(role);
		if (func === 'requestFolders') return ['AGR', 'ASO', 'SUP', 'INS', 'AUT'].includes(role);
		return false;
	},
	roleSupportsProfileSupData: (state) => {
		let role = state.details?.staticData?.role;
		switch (role) {
			case 'AGR':
				return 1;
			case 'SUP':
				return 20;
			default:
				return 0;
		}
	},
	isLoaded: (state) => {
		return state.loaded;
	},
	email: (state) => {
		return state.details.userData.address?.email;
	},
	hasRole: (state, getters) => (role) => {
		let baseRole = getters.role;
		if (role === 'AGR' && baseRole === role) {
			return true;	//don't check for active ins. this is mainly used for nav menu, and inactive agr can still see requests
		}
		if (baseRole === role) return true;
		if (role === 'SUP' && baseRole === 'AGR') {
			return getters.isActiveSup;
		}
		return false;
	},
	isActiveSup: (state, getters) => {
		return ['AGR', 'SUP'].includes(getters.role) && getters.hasAcceptedTos;
	},
	isActiveAgr: (state, getters) => {
		let insData = state.details?.userData?.insData;
		return getters.role === 'AGR' && insData && insData.filter(e => !e.deactivatedOn).length > 0;
	},
	hasActiveAso: (state, getters) => {
		let asoData = state.details?.userData?.asoData;
		return getters.role === 'AGR' && asoData && asoData.filter(e => !e.deactivatedOn).length > 0;
	},
	supHasAllGeoPos: (state) => {
		let account = state.details;
		if (!account) return false;
		for (let supData of account.userData.supData) {
			for (let los of supData.locationsOfSale) {
				let pos = los.address.geoPosition;
				if (!(pos && pos.lat && pos.lon)) return false;
			}
		}
		return true;
	},
	supHasAllMailOrPhone: () => {
		let account = state.details;
		if (!account) return false;
		let atLeastOneLos = false;
		for (let supData of account.userData.supData) {
			for (let los of supData.locationsOfSale) {
				if (!(los.address.phone || los.address.email)) return false;
				atLeastOneLos = true;
			}
		}
		return atLeastOneLos;
	},
	supIsReadyToSell: (state, getters) => {
		return getters.supHasAllGeoPos && getters.supHasAllMailOrPhone;
	},
	hasAcceptedTos: (state) => {
		return !!state.details?.userData?.supExtra?.tosAcceptedOn;
	},
	activeInsOptions: (state) => {
		let insData = state.details?.userData?.insData;
		if (!insData) return [];
		return insData.filter(e => !e.deactivatedOn);
	},
	activeAsoOptions: (state) => {
		let asoData = state.details?.userData?.asoData;
		if (!asoData) return [];
		return asoData.filter(e => !e.deactivatedOn);
	}
};

const actions = {

	async loadAccountData({ commit, rootGetters }) {
		let accountDetails = {};
		if (rootGetters['auth/token/account']) {
			accountDetails = await getAccountDetails();
		}
		commit('setDetails', accountDetails);
	},

	async loadAvailableAccounts({ commit }) {
		let list = await listAccountsForUser();
		if (list.length === 0) return;
		let entities = await getEntitiesById(list);
		commit('setAvailableAccounts', entities);
	},

	async switchToFirstAvailableAccount({ dispatch, getters, rootGetters }) {
		await dispatch('loadAvailableAccounts');
		if (getters.availableAccounts.length > 0) {
			let country = rootGetters['country/currentCountry']?.id;
			let lastAccount = LocalStorage.getLastUsedAccountId(country);
			if (rootGetters['auth/token/account'] && getters.availableAccounts.find(a => a.id === rootGetters['auth/token/account'])) {
				await dispatch('loadAccountData');
			} else if (lastAccount && getters.availableAccounts.find(a => a.id === lastAccount)) {
				await dispatch('auth/token/switchAccountAndLogin', lastAccount, { root: true });
			} else {
				await dispatch('auth/token/switchAccountAndLogin', getters.availableAccounts[0].id, { root: true });
			}
			if (typeof _etracker === 'object' && EtrackerCode) {
				// eslint-disable-next-line no-undef
				_etracker.sendEvent(new et_UserDefinedEvent('role', 'login', '', getters.role));
			}
		}
	},

	async unregisterFromAccount({ rootGetters, dispatch }, account) {
		if (!account) account = rootGetters['auth/token/account'];
		if (!account) throw new Error('no account is selected');
		await unregisterAccount(account);
		// commit('auth/token/logout', null, { root: true });
		dispatch('switchToFirstAvailableAccount');
	},

	async updateUserData({ state, commit }, { userData, surpressError }) {
		let accountData = await updateAccount(userData, surpressError);
		let details = JSON.parse(JSON.stringify(state.details));
		details.userData = accountData.userData;
		commit('setDetails', details);
	},

	async updateUserGeoPos({ state, dispatch }, { userId, countryId, locationOfSale, geoPosition }) {
		if (userId) {
			await TdbRepo.updateGeoPosition({ userId, countryId, locationOfSale, geoPosition });
			await dispatch('admin/clearCache', null, { root: true });
			await dispatch('request/main/loadAgrData', null, { root: true });
		} else { // add to current user
			let userData = JSON.parse(JSON.stringify(state.details.userData));
			userData.supData.find(e => e.country === countryId).locationsOfSale
				.find(el => el.id === locationOfSale).address.geoPosition = geoPosition;
			await dispatch('updateUserData', { userData });
			await dispatch('request/main/init', null, { root: true });

		}
	},

	async registerWithInvite({ dispatch, rootState }, { inviteId, registrationName }) {
		await dispatch('auth/token/initApp', null, { root: true });
		let token = rootState.auth.token.ssoToken;
		let accountId = await registerWithDomainByInvite(inviteId, registrationName, token);
		await dispatch('auth/token/switchAccountAndLogin', accountId, { root: true });
	}
};

const mutations = {

	setDetails(state, details) {
		state.details = details;
		state.loaded = true;
	},

	setAsoData(state, asoData) {
		state.details.userData.asoData = asoData;
	},

	setAvailableAccounts(state, list) {
		state.availableAccounts = list;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
